import {Button, Grid, Typography} from '@mui/material';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import screenAutoImg from '../images/screen_auto_height.png';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {io, Socket} from 'socket.io-client';
import {useCookies} from "react-cookie";
import {JobApi, PostTargetPosPosEnum} from "../generated/job";
import { useStationStore } from '../store/store';
// import {useAuth} from "oidc-react";
// import { useNavigate } from 'react-router-dom';

const multiPosTargets: number[] = [9, 10, 17];
const lhd_rhdPosTarget: number[] = [15];

const Target = () => {
    const [height, setHeight] = useState(0);
    const [DBHeight, setDBHeight] = useState("");
    const [moveDownHeight, setMoveDownHeight] = useState(height - 10)
    const [moveUpHeight, setMoveUpHeight] = useState(height + 10)
    const [showPositions, setShowPositions] = useState(false)
    const [textFieldValueUp, setTextFieldValueUp] = useState('');
    const [textFieldValueDown, setTextFieldValueDown] = useState('');
    
    const [showPositionsLhdRhd, setshowPositionsLhdRhd] = useState(false);

    const [allowSetCookie, setAllowSetCookie] = useState(true);
    const allowTelemetryUpdatesRef = useRef(true);

    const [cookies,setCookies] = useCookies();

    // const auth = useAuth();
    // let jobApi: JobApi = new JobApi();

    let jobApi = useMemo(() => new JobApi(),[]);

    // const [socket, setSocket] = useState<any>();

    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase();
    const stationDevices = stationStore.devices;

    const socketRef = useRef<Socket>();


    const moveUp = () => {
        console.log(`{"pos": ${moveUpHeight} }`)

        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Table"){
                    if(textFieldValueUp !== ''){
                        if(socketRef.current){
                            socketRef.current.emit("pos", dev.id!, `{"pos": ${moveUpHeight} }`)
                        }
                    }else{
                        if(socketRef.current){
                            socketRef.current.emit("pos", dev.id!, `{"pos": ${height + 10} }`)
                        }
                    }
                    
                    break;
                }
    
            }
        }
    }
    const moveDown = () => {
        console.log(`{"pos": ${moveDownHeight} }`)

        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Table"){
                    if(textFieldValueUp !== ''){
                        if(socketRef.current){
                            socketRef.current.emit("pos", dev.id!, `{"pos": ${moveDownHeight} }`)
                        }
                    }else{
                        if(socketRef.current){
                            socketRef.current.emit("pos", dev.id!, `{"pos": ${height - 10} }`)
                        }
                    }
                    
                    break;
                }
            }
        }
    }

    const changeUp = (event: any) => {
        const inputValue = event.target.value;
        if(!isNaN(inputValue)){
            setTextFieldValueUp(inputValue);
            setMoveUpHeight(height + parseInt(inputValue));
        }else{
            alert('Bitte geben Sie nur Zahlen ein. (In mm!)')
        }

    }

    const changeDown = (event: any) => {
        const inputValue = event.target.value;
        if(!isNaN(inputValue)){
            setTextFieldValueDown(event.target.value);
            setMoveDownHeight(height - parseInt(event.target.value));
        }else{
            alert('Bitte geben Sie nur Zahlen ein. (In mm!)')
        }
        
    }





    // useEffect(() => {
    //     console.log("mount target");

    //     return () => {
    //         console.log("unmount target");
    //     }
    // }, []);


    // useEffect(() => {
    //     console.log("stationId changed", stationId);

    //     return () => {
    //         console.log("reset stationId changed", stationId);
    //     }
    // }, [stationId]);

    // useEffect(() => {
    //     console.log("stationDevices changed", stationDevices);

    //     return () => {
    //         console.log("reset stationDevices changed", stationDevices);
    //     }
    // }, [stationDevices]);



    const reset = () => {
        console.log("reset");

        allowTelemetryUpdatesRef.current = false;

        setAllowSetCookie(false);

        if(!socketRef.current){
            socketRef.current = io(
                `https://data.data2work.com/${stationId}`,
                {
                    transports: ["websocket"]
                }
            );


        }

        // let socket = io(`https://data.data2work.com/${stationId}`,
        //     {
        //         transports: ["websocket"]
        //     })
        
        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Table"){
    
                    socketRef.current.emit("pos", dev.id!, `{"pos": 0 }`)
    
                     break;
                }
            }
        }
        
        // return() =>{
            allowTelemetryUpdatesRef.current = true;

            if(socketRef.current){
                socketRef.current.disconnect();
            }
        // }

    }

    const resetCallback = useCallback(reset, [stationDevices, stationId]);

    useEffect(() => {
        if(stationId != null){
            console.log("init socket");
    
            let mysocket  = io(
                `https://data.data2work.com/${stationId}`,
                {
                    transports: ["websocket"]
                }
            );
    
            socketRef.current = mysocket;
        }

        return (() => {
            
            resetCallback();

            if(socketRef.current){
                socketRef.current.disconnect();
            }
        });

        


    }, [resetCallback, stationId]);

    // useEffect(() => {
    //     console.log("init socket");
        
    //     let mysocket  = io(`https://data.data2work.com/${stationId}`,
    //         {
    //             transports: ["websocket"]
    //         });
    //     setSocket(mysocket);



    // }, [stationId]);

    


    useEffect(() => {
        let werte: any = null;

        if(socketRef.current === undefined || !allowSetCookie){
            return;
        }

        console.log("Cookies Allow"+allowSetCookie);

        if(!allowSetCookie){
            return;
        }

        if(!stationDevices){
            return;
        }

        if (cookies['einstellwert'] !== undefined) {
            if (cookies['einstellwert'].werte !== undefined) {
                werte =  JSON.parse(cookies['einstellwert'].werte)
                console.log(werte);
            }
        }

        socketRef.current.on("connect", () => {

            if(socketRef.current){
                socketRef.current.on("telemetry", (...data: any[]) => {
                    if(data[0].includes('table')){
                        let json = JSON.parse(data[1])
                        setHeight(json['pos']);
                        if(allowTelemetryUpdatesRef.current){
                            setCookies("hist", json['pos']+110+542+548 + " mm");
                        }
                        
    
                    }
                })
            }

            if(werte != null && werte !== undefined){
                console.log("MultiPosTarget:" + multiPosTargets);
                console.log(cookies['einstellwert'].kalibriertafelID);
                console.log("Höhe Kalibriertafel"+werte['Höhe_Kalibriertafel']);
                if(multiPosTargets.includes(cookies['einstellwert'].kalibriertafelID)){
                    setShowPositions(true);
                    jobApi.postTargetPos({ "id": cookies['jobId'],"pos": PostTargetPosPosEnum.Pos1}).then(r => {
                        console.log("r" + r);
                    });
                }
                if(lhd_rhdPosTarget.includes(cookies['einstellwert'].kalibriertafelID)){
                    console.log("Targets ist ein lhd_rhd Target!");
                    setshowPositionsLhdRhd(true);
                    jobApi.postTargetPos({ "id": cookies['jobId'],"pos": PostTargetPosPosEnum.Lhd}).then(r => {
                        console.log("r" + r);
                    });
                }
                let h;
                if(typeof werte['Höhe_Kalibriertafel'] === 'string'){
                    console.log("TRUE");
                    h = werte['Höhe_Kalibriertafel'].substring(0, werte['Höhe_Kalibriertafel'].length - 2); // remove mm
                    setDBHeight(werte['Höhe_Kalibriertafel']);
                }else{
                    h = werte['Höhe_Kalibriertafel']; 
                    setDBHeight(werte['Höhe_Kalibriertafel'] + " mm");
                    console.log("FALSE");
                }


                setHeight(parseInt(h));

                if(allowTelemetryUpdatesRef.current){
                    setCookies("hist", parseInt(h+110+542+548));
                }
                

                console.log(`{"pos": ${h} }`);

                for (const dev of stationDevices) {
                    if(dev.type === "Table"){
                        if(socketRef.current){
                            socketRef.current.emit("pos", dev.id!, `{"pos": ${h-110-542-548} }`)
                        }

                        break;
                    }
                }
            }

        });
    }, [allowSetCookie, cookies, setCookies, jobApi, stationDevices]);

    // useEffect(() => {
    //     console.log("reset");
        
    //     // Diese Funktion wird aufgerufen, wenn die Komponente demontiert wird
    //     // return () => {resetCallback()};
    //     return resetCallback();
    // }, [resetCallback]); 

    const posButtonClick = (pos: PostTargetPosPosEnum) => {
        jobApi.postTargetPos({"id": cookies['jobId'], "pos": pos}).then(r => {
            console.log("r:" + r);
        });
    }

    console.log("render target");
    
    return (
        <Grid container sx={{ width: '100vw', height: '100vh' }} alignItems={'center'} textAlign={'center'}>
            <Grid item xs={6}>
                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <img src={screenAutoImg} alt="Target Height" style={{ width: "50vh", height: "50vh" }}></img>
                    </Grid>
                    {showPositions && (
                        <><Grid item xs={4}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos2)}>
                                Position 2
                            </Button>
                        </Grid><Grid item xs={4}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos1)}>
                                Position 1
                            </Button>
                        </Grid><Grid item xs={4}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos3)}>
                                Position 3
                            </Button>
                        </Grid></>
                )}
                {showPositionsLhdRhd && (
                        <><Grid item xs={6}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Lhd)}>
                                LHD
                            </Button>
                        </Grid><Grid item xs={6}>
                        <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Rhd)}>
                                RHD
                            </Button>
                        </Grid></>
                )}
                </Grid>
            </Grid>
            <Grid item xs={6} sx={{ height: '100%' }}>
                <Grid container sx={{ width: '100%', height: '100%' }} direction="column" justifyContent={'space-evenly'} justifyItems={'space-evenly'} alignItems={'center'}>
                    <Grid item xs={2}>
                        <input type="text" style={{height: "5vh"}} value={textFieldValueUp} onChange={changeUp}></input>
                        <Button variant='contained' onClick={moveUp}>
                            <ArrowUpward sx={{height:'40px'}}/>
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h3'>Soll-Höhe: {DBHeight}</Typography>
                        <Typography variant='h3'>Ist-Höhe: {height+110+542+548} mm</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <input type="text" style={{height: "5vh"}} value={textFieldValueDown} onChange={changeDown} ></input>
                        <Button variant='contained' onClick={moveDown}>
                            <ArrowDownward sx={{height:'40px'}}/>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Target;