import { CloudOff, CloudQueue } from "@mui/icons-material";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { io } from "socket.io-client";
import { useStationStore } from "../store/store";


const ConnectionStatus = () => {
    const theme = useTheme();

    const [connected, setConnected] = useState<boolean>(true);
    
    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase();
    
    const socketRef = useRef<any>();

    useEffect(() => {
        let mysocket  = io(
            `https://data.data2work.com/${stationId}`,
            {
                transports: ["websocket"]
            }
        );

        socketRef.current = mysocket;

        return (() => {
            if(socketRef.current){
                socketRef.current.disconnect();
            }
        });


    }, [stationId]);


    useEffect(() => {
        socketRef.current.on("connect", () => {
            console.log("connect...");

            setConnected(true);

            const engine = socketRef.current.io.engine;
        
            engine.on("close", () => {
                console.log("close...");
                setConnected(false);

            });
        });

        socketRef.current.on("disconnect", () => {
            console.log("disconnect...");
            setConnected(false);
        });

        
    }, []);

    return (
        <Grid 
            alignItems={"flex-end"} 
            display={"flex"} 
            flexDirection={"column"} 
            // width="100%" 
            padding={0.5}
        >
            {connected ? 
                <Box bgcolor={theme.palette.success.main} color={"white"} lineHeight={0} padding={0.5} borderRadius={"50%"} border="1px solid white">
                    <CloudQueue />
                </Box>
            :
                <Box bgcolor={theme.palette.error.main} color={"white"} lineHeight={0} padding={0.5} borderRadius={"50%"} border="1px solid white">
                    <CloudOff />
                </Box>
            }

        </Grid>

    );
}

export default ConnectionStatus;