// useWebSocket.js
import { useState, useEffect, useRef } from 'react';
import {io} from "socket.io-client";
import useInterval from './interval';

const useLaserSocket = (station: string, laserPos: number) => {
  
  const [distance, setDistance] = useState("0");
  const device = useRef<any>(null);
  const [socket, setSocket] = useState<any>(null);

  

  useEffect(() => {
    const socketOwn = io(`https://data.data2work.com/${station.toLowerCase()}`, {
        transports: ["websocket"]

        });
    setSocket(socketOwn);


    return () => {
        if(device.current){
            socketOwn.emit("dis", device.current.id!.toLowerCase(),'{"pos":' + laserPos.toString() + '}')
        }
    
        socketOwn.disconnect();
    };
  }, [station]);

  useEffect(() => {

    if (!socket)
    {
        return;
    }
 
    function onConnect() {
        console.log("Connected to Socket");
    }

    function onTelemetryEvent(...data: any[]) {
        if(device.current && device.current.id === data[0]){
            let json = JSON.parse(data[1])
            setDistance(json['distance']);
        }
      }

     socket.on('telemetry', onTelemetryEvent);

     if (device.current)
     {
        console.log(`emit connect to ${ device.current.id!.toLowerCase()}`);
        socket.emit("con",  device.current.id!.toLowerCase(),'{"pos":' + laserPos.toString() + '}');
     }

      return () => {
        socket.off('connect', onConnect);
    
        socket.off('foo', onTelemetryEvent);
      };
    
  }, [ socket]);

  useInterval(() => {
    
    if (socket)
    {
        console.log(`emit connect to ${ device.current.id!.toLowerCase()}`);
    socket.emit("con",  device.current.id!.toLowerCase(),'{"pos":' + laserPos.toString() + '}');

    }
    

  }, device.current ? 10000 : null);

  const connect = (deviceParam:any) => {

    
    if (!device.current || deviceParam.id !== device.current.id)
    {
        disconnect();
        device.current = deviceParam;
    }

    if (socket)
    {
        console.log(`emit connect to ${ device.current.id!.toLowerCase()}`);
        socket.emit("con",  device.current.id!.toLowerCase(),'{"pos":' + laserPos.toString() + '}');
    }
       
  };

    const disconnect = () => {
        
        if (!socket)
        {
            return;
        }
        if (!device.current)
        {
            return
        }

        const current = device.current;

        device.current = null;

        console.log(`emit disconnect to ${ current.id!.toLowerCase()}`);
        socket.emit("dis",  current.id!.toLowerCase(),'{"pos":' + laserPos.toString() + '}');
    };


  return {distance, connect, disconnect };
};

export default useLaserSocket;