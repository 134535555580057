import {useEffect, useState} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import radnabenmitte_img from '../../images/radnabenmitte_vertikal.png';
import { ArrowDownward, ArrowUpward, Done } from '@mui/icons-material';
import {useCookies} from "react-cookie";
import {Einstellwerte} from "../../generated/car";
import useLaserSocket from "../../components/laserSocket";
import { useStationStore } from '../../store/store';

const COLOR_WARNING = 'red';

const Radnabenmitte = () => {
    const [cookies, setCookies] = useCookies();
    const [refDistance, setRefDistance] = useState(1500);
    const [colorRight, setColorRight] = useState(COLOR_WARNING);
    const [colorLeft, setColorLeft] = useState(COLOR_WARNING);

    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase() || "";
    const stationDevices = stationStore.devices;


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {distance: leftDistance, connect : leftConnect, disconnect: leftDisconnect} = useLaserSocket(stationId, 1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {distance: rightDistance, connect : rightConnect, disconnect: rightDisconnect} = useLaserSocket(stationId, 1);

    const [iconLeft, setIconLeft] = useState(<ArrowDownward sx={{height:'7vh', width:'5vw', color:COLOR_WARNING}} />);
    const [iconRight, setIconRight] = useState(<ArrowDownward sx={{height:'7vh', width:'5vw', color:COLOR_WARNING}} />);
    // const [mac, setMac] = useState({'left': '', 'right': ''});
    const [phase, setPhase] = useState('Phase 1');

    let einstellwerte:Einstellwerte = cookies['einstellwert'];

    useEffect(() => {

        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Distance" && dev.tags?.includes("left")){
                    leftConnect(dev);
                }
    
                if(dev.type === "Distance" && dev.tags?.includes("right")){
                    rightConnect(dev);
                }
            }

        }
     
        const test = localStorage.getItem("Phase");
        if(test != null){
            setPhase(test);
        }

        

        if (einstellwerte != null) {
            if (einstellwerte.werte != null) {
                let werte = JSON.parse(einstellwerte.werte);

                if(localStorage.getItem("Phase") === "Phase 1"){
                    if(werte['Abstand_Kalibriertafel'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel'].substring(0, werte['Abstand_Kalibriertafel'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel']));
                        }
                    }
                }else{
                    if(werte['Abstand_Kalibriertafel_Phase2'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2'].substring(0, werte['Abstand_Kalibriertafel_Phase2'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2']));
                        }
                    }
                }
                
            }
        }       

    }, [leftConnect, rightConnect, stationDevices]);


    useEffect(() => {


        const phase = localStorage.getItem("Phase");
        if(phase != null){
            setPhase(phase);
        }
        

        if (einstellwerte != null) {
            if (einstellwerte.werte != null) {
                let werte = JSON.parse(einstellwerte.werte);

                

                if(phase === "Phase 1"){
                    if(werte['Abstand_Kalibriertafel'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel'].substring(0, werte['Abstand_Kalibriertafel'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel']));
                        }
                    }
                }else{
                    if(werte['Abstand_Kalibriertafel_Phase2'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2'].substring(0, werte['Abstand_Kalibriertafel_Phase2'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2']));
                        }
                    }
                }
                
            }
        }

        
        const rightDistanceStr = String(rightDistance).replaceAll(".", "");

        const leftDistanceStr = String(leftDistance).replaceAll(".", "");

        if(parseInt(rightDistanceStr) >= refDistance - 20 && parseInt(rightDistanceStr) <= refDistance + 20){
            setColorRight('green');
            setIconRight(<Done sx={{height: '7vh', width: '5vw', color: 'green'}}/>);
        }else{
            setColorRight(COLOR_WARNING);
            if(parseInt(rightDistanceStr) > refDistance) {
                setIconRight(<ArrowUpward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }else{
                setIconRight(<ArrowDownward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }
        }

        if(parseInt(leftDistanceStr) >= refDistance - 20 && parseInt(leftDistanceStr) <= refDistance + 20){
            setColorLeft('green');
            setIconLeft(<Done sx={{height: '7vh', width: '5vw', color: 'green'}}/>);
        }else{
            setColorLeft(COLOR_WARNING);
            if(parseInt(leftDistanceStr) > refDistance) {
                setIconLeft(<ArrowUpward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }else{
                setIconLeft(<ArrowDownward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }
        }

        if(phase === "Phase 1"){
            setCookies("rist", rightDistance + " mm");
            setCookies("list", leftDistance + " mm");
        }else if(phase === "Phase 2"){
            setCookies("rist_phase2", rightDistance + " mm");
            setCookies("list_phase2", rightDistance + " mm");
        }
        else{
            setCookies("rist", rightDistance + " mm");
            setCookies("list", leftDistance + " mm");
        }
        

    }, [rightDistance, leftDistance, colorLeft, colorRight, iconLeft, iconRight, refDistance, setCookies]);

    return (
        <Box sx={{ backgroundColor: 'lightgrey', width: '100vw', height: '100vh', justifyContent: 'center', alignContent: 'center', textAlign:'center', verticalAlign:'middle' }}>
            <Box sx={{height: '3vh'}}></Box>
                <Typography variant='h3' >{phase}</Typography>
                <Grid container direction={'row'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} spacing={0}>
                    <Grid item xs={3}>
                        <Typography variant='h3' >Laser Rechts:</Typography>
                        <Typography variant='h3' >Soll-Wert: {refDistance} mm</Typography>
                        <Typography variant='h3'>
                            <span>Ist-Wert:</span>{' '}
                            <span style={{color: colorRight}} >{rightDistance} mm</span>
                        </Typography>
                        {iconRight}
                    </Grid>
                    <Grid item xs={6} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <img src={radnabenmitte_img} style={{ width: "40vh", height: "80vh",transform:'rotate(180deg)' }} alt='Radnabenmitte' />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='h3' >Laser Links:</Typography>
                        <Typography variant='h3' >Soll-Wert: {refDistance} mm</Typography>
                        <Typography variant='h3'>
                            <span>Ist-Wert:</span>{' '}
                            <span style={{color: colorLeft}} >{leftDistance} mm</span>
                        </Typography>
                        {iconLeft}
                    </Grid>
                </Grid>
        </Box>
    )
}


export default Radnabenmitte;